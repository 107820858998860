<template>
  <window-content v-if="!loading && !errors && !success">
    <div class="m-t">
      <e-row class=" erp-row-col-md">
        <e-col style="max-width: 240px">
          <erp-s-field
              view="lr"
              label="ID:"
              label-width="110px"
          >
            <erp-input simple-border v-model="vistoriador.id" disable />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 230px">
          <erp-s-field
              view="lr"
              label="Apelido:"
              label-width="50px"
          >
            <apelido-input :nome="vistoriador.pessoa.name" :gerar-blur="!vistoriador.id"
                           v-model="vistoriador.apelido" />
          </erp-s-field>
        </e-col>

        <e-col>
          <tipo-vistoriador-select label="Tipo:" field-view="lr" simple-border="" v-model="vistoriador.pessoa.type" />
        </e-col>

        <e-col style="max-width: 260px">
          <status-select label="Status:" placeholder="Selecione" field-view="lr" simple-border=""
                         v-model="vistoriador.status" />
        </e-col>

      </e-row>

      <e-row class=" erp-row-col-md">
        <e-col>
          <erp-s-field
              view="lr"
              :label="vistoriador.pessoa.type === 1 ? 'Nome completo:' : 'Razão social:'"
              label-width="110px"
          >
            <erp-input simple-border v-model="vistoriador.pessoa.name" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 340px">
          <erp-s-field
              view="lr"
              :label="vistoriador.pessoa.type === 1 ? 'CPF:' : 'CNPJ:'"
              label-width="40px"
          >
            <erp-input simple-border v-model="vistoriador.pessoa.document" v-mask="'###.###.###-##'"
                       v-if="vistoriador.pessoa.type === 1" />
            <erp-input simple-border v-model="vistoriador.pessoa.document" v-mask="'##.###.###/####-##'" v-else />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class=" erp-row-col-md">
        <e-col>
          <erp-s-field
              view="lr"
              label="E-mail:"
              label-width="110px"
          >
            <erp-input simple-border v-model="vistoriador.pessoa.emails[0].email" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Celular:"
              label-width="50px"
          >
            <erp-input simple-border v-model="vistoriador.pessoa.phoneNumbers[0].phoneNumber"
                       v-mask="'(##) # ####-####'" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Residencial:"
              label-width="70px"
          >
            <erp-input simple-border v-model="vistoriador.pessoa.phoneNumbers[1].phoneNumber"
                       v-mask="'(##) ####-#####'" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Comercial:"
              label-width="70px"
          >
            <erp-input simple-border v-model="vistoriador.pessoa.phoneNumbers[2].phoneNumber"
                       v-mask="'(##) ####-#####'" />
          </erp-s-field>
        </e-col>

      </e-row>
    </div>
    <erp-tabs @change="changeTab" :active="tabActive">
      <erp-tab-item name="principal">Principal</erp-tab-item>
<!--      <erp-tab-item name="endereco"></erp-tab-item>-->
      <erp-tab-item name="contato">Endereço e Contato</erp-tab-item>
      <!--<erp-tab-item name="planilhas">Planilha de custos</erp-tab-item>-->
      <erp-tab-item name="logs" v-if="vistoriador.id">Logs</erp-tab-item>
    </erp-tabs>
    <window-body class="font-11" :class="{'flex col-grow': tabActive === 'logs' || tabActive === 'arremates'}" tabs>
      <div v-show="tabActive === 'principal'" class="wrapper m-t">
        <e-row>
          <e-col>
            <e-row v-if="isPessoaFisica" mr>
              <e-col style="min-width: 280px">
                <erp-s-field
                    view="lr"
                    label="RG:"
                    label-width="90px"
                >
                  <erp-input v-model="vistoriador.pessoa.documents[0].identifier" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Emissor:"
                >
                  <erp-input v-model="vistoriador.pessoa.documents[0].agentDispatcher" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Expedição:"
                >
                  <erp-input v-model="vistoriador.pessoa.documents[0].expedition" />
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row v-if="isPessoaFisica" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Nascimento:"
                    label-width="90px"
                >
                  <erp-input v-model="vistoriador.pessoa.birthDate" v-mask="'##/##/####'" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Nacionalidade:"
                >
                  <erp-input v-model="vistoriador.pessoa.nationality" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Sexo:"
                >
                  <erp-select placeholder="Selecione"
                              :options="[{label: 'Masculino', value: 1},{label: 'Feminino', value: 2}]"
                              v-model="vistoriador.pessoa.gender" />
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row v-if="isEmpresa" mr>
              <e-col style="max-width: 260px">
                <erp-s-field
                    view="lr"
                    label="Proprietário:"
                    label-width="90px"
                >
                  <erp-input v-model="vistoriador.pjProprietarioNome" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="CPF:"
                >
                  <erp-input v-model="vistoriador.pjProprietarioCpf" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="RG:"
                >
                  <erp-input v-model="vistoriador.pjProprietarioRg" />
                </erp-s-field>
              </e-col>
            </e-row>

            <!--<e-row class="" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label-width="90px"
                    tag="div"
                >
                  <erp-checkbox class="flex items-center" v-model="vistoriador.permitirCompraBlindado">
                    <label class="no-select font-bold">
                      <span class="m-l-xs">Habilitar compra de blindados</span>
                    </label>
                  </erp-checkbox>
                </erp-s-field>
              </e-col>
            </e-row>-->

            <e-row mr class="m-t">
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Descrição interna:"
                    class="wrap label-wrap"
                >
                  <textarea class="w-full erp-input wrapper-xs" rows="4"
                            v-model="vistoriador.observacao"></textarea>
                </erp-s-field>
              </e-col>
            </e-row>
          </e-col>
          <e-col class="m-l-xxl" style="min-width: 216px; max-width: 216px">
            <foto ref="foto" :arrematante="vistoriador" @update="updateFoto" @toDelete="deleteFotoEvent" />
          </e-col>
        </e-row>
        <e-row class="m-t">
          <e-col>
            <div class="font-bold m-b-sm">Acesso</div>
            <acesso :entidade="vistoriador" />
          </e-col>
        </e-row>
        <e-row class="m-t">
          <e-col>
            <div class="font-bold m-b-sm font-11">Após o cadastro ou alteração:</div>
            <e-row>
              <e-col>
                <erp-checkbox class="flex no-wrap items-center m-r" v-model="vistoriador.enviarBoasVindas">
                  <label class="no-select text-nowrap font-bold">
                    <span class="m-l-xs text-nowrap">Enviar e-mail de boas vindas</span>
                  </label>
                </erp-checkbox>

                <erp-checkbox class="flex no-wrap items-center m-r" v-model="vistoriador.solicitarCriacaoSenha">
                  <label class="no-select text-nowrap font-bold">
                    <span class="m-l-xs text-nowrap">Solicitar criação de senha</span>
                  </label>
                </erp-checkbox>
              </e-col>
            </e-row>
          </e-col>
        </e-row>
      </div>
<!--      <endereco v-show="tabActive === 'endereco'" class="wrapper m-t" :entidade="vistoriador" />-->
      <contato v-show="tabActive === 'contato'" class="wrapper m-t" :entidade="vistoriador" />
      <div v-if="vistoriador.id && tabActive === 'logs'" class="wrapper flex col-grow">
        <log :usuario="vistoriador" type="vistoriador" />
      </div>
    </window-body>
    <window-footer class="flex content-between">
      <!--<div class="flex items-center justify-center font-11">
        <div class="m-l-xs">
          Ao salvar o leilão ele ficará com status Rascunho. Após validar as informações, altere o
          status para Em breve.
        </div>
      </div>-->
      <div class="inline-block window-actions text-right self-end col-grow">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save" />
      </div>
    </window-footer>
  </window-content>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
    </div>
    <h5 v-if="!id" class="m-t">Vistoriador registrado com sucesso!</h5>
    <h5 v-else class="m-t">Vistoriador atualizado com sucesso!</h5>
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
/* eslint-disable */
import {
  WindowContent,
  WindowBody,
  WindowFooter,
  ErpTabItem,
  ErpTabs,
  ErpSField,
  ErpInput,
  ErpLabel,
  ErpSelect,
  ErpCheckbox
} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../../layout/window/Loading'
import TimerCloseWindow from '../../../layout/window/TimerClose'
import WindowSuccess from '../../../layout/window/Success'
import {date} from 'uloc-vue'
import {VMoney} from 'v-money'
import {REAL_BRL, convertRealToMoney} from '../../../../utils/money'
import {
  find,
  newVistoriador,
  updateVistoriador,
  updateFotoVistoriador
} from '../../../../domain/vistoriador/services/index'
import DatetimeInput from '../../../../reuse/input/Datetime'
import DefaultAsyncSelect from '../../../../reuse/input/DefaultAsyncSelect'
import StatusSelect from '../../../arrematante/components/include/StatusArrematanteSelect'
import PatioInput from '../../../patio/helpers/input/PatioInput'
import ERow from '../../../layout/components/Row'
import ECol from '../../../layout/components/Col'
import TipoVistoriadorSelect from '../../../arrematante/components/include/TipoArrematanteSelect'
import ApelidoInput from '../../../arrematante/components/include/ApelidoInput'
import {EstadoCivil, ESTADO_CIVIL_CASADO} from '../../../../domain/arrematante/helpers/Status'
import Acesso from '../include/Acesso'
import Log from '../../../usuario/components/include/Log'
import {adjustPurposes} from '../../../../domain/pessoa/helpers/Purpose'
import {TIPO_PESSOA_JURIDICA} from '../../../../domain/pessoa/helpers/Status'
import Foto from '../../../arrematante/components/include/Foto'
import Contato from '../../../pessoa/components/include/Contato'
import Endereco from '../../../pessoa/components/include/Endereco'

import {mockEndereco} from '../../../pessoa/mock/endereco'
import {mockTelefone} from '../../../pessoa/mock/telefone'
import {mockEmail} from '../../../pessoa/mock/email'
import {mockContato} from '../../../pessoa/mock/contato'
import {mockDocument} from '../../../pessoa/mock/document'

let mockVistoriador = {
  id: null,
  apelido: null,
  podeReceberCotacoes: true,
  atendeQualquerComitente: false,
  newsletter: true,
  score: null,
  pjProprietarioCpf: null,
  pjProprietarioNome: null,
  pjProprietarioRg: null,
  pjProprietarioTelefoneComercial: null,
  observacao: null,
  pessoa: {
    id: null,
    name: null,
    type: 1,
    document: null,
    birthDate: null,
    nationality: null,
    gender: null,
    addresses: [JSON.parse(JSON.stringify(mockEndereco))],
    phoneNumbers: [JSON.parse(JSON.stringify(mockTelefone)), JSON.parse(JSON.stringify(mockTelefone)), JSON.parse(JSON.stringify(mockTelefone))],
    emails: [JSON.parse(JSON.stringify(mockEmail))],
    contacts: [JSON.parse(JSON.stringify(mockContato)), JSON.parse(JSON.stringify(mockContato)), JSON.parse(JSON.stringify(mockContato))],
    documents: [JSON.parse(JSON.stringify(mockDocument))]
  },
  enviarBoasVindas: true,
  solicitarCriacaoSenha: true,
  status: 0
}

export default {
  name: 'VistoriadorWindow',
  directives: {money: VMoney},
  props: ['id'],
  data () {
    return {
      money: REAL_BRL,
      tabActive: 'principal',
      loading: !!this.id,
      errors: null,
      success: false,
      status: null,
      deleteFoto: false,
      vistoriador: JSON.parse(JSON.stringify(mockVistoriador))
    }
  },
  mounted () {
    this.load()
  },
  computed: {
    EstadoCivil () {
      return EstadoCivil
    },
    EstadoCivilCasado () {
      return ESTADO_CIVIL_CASADO
    },
    isPessoaFisica () {
      return this.vistoriador.pessoa.type === 1
    },
    isEmpresa () {
      return this.vistoriador.pessoa.type === 2
    }
  },
  watch: {},
  methods: {
    load () {
      this.id && find(this.id || this.vistoriador.id)
        .then(response => {
          let data = response.data
          if (data.pessoa.birthDate && data.pessoa.birthDate.date) {
            data.pessoa.birthDate = date.formatDate(data.pessoa.birthDate.date, 'DD/MM/YYYY')
          }
          // let mock = JSON.parse(JSON.stringify(mockArrematante))
          // console.log(mock)
          // this.arrematante = Object.assign(mock, data)
          // console.log(this.arrematante)
          if (!data.pessoa.addresses || data.pessoa.addresses.length === 0) {
            data.pessoa.addresses = [JSON.parse(JSON.stringify(mockEndereco))]
          }

          if (!data.pessoa.phoneNumbers || data.pessoa.phoneNumbers.length < 3) {
            if (!Array.isArray(data.pessoa.phoneNumbers)) {
              data.pessoa.phoneNumbers = []
            }
            const len = 3 - Number(data.pessoa.phoneNumbers.length)
            for (let i = 0; i < len; i++) {
              data.pessoa.phoneNumbers.push(JSON.parse(JSON.stringify(mockTelefone)))
            }
          }

          if (!data.pessoa.emails || data.pessoa.emails.length === 0) {
            data.pessoa.emails = [JSON.parse(JSON.stringify(mockEmail))]
          }

          if (!data.pessoa.documents || data.pessoa.documents.length === 0) {
            data.pessoa.documents = [JSON.parse(JSON.stringify(mockDocument))]
          }

          adjustPurposes([
            data.pessoa.addresses,
            data.pessoa.phoneNumbers,
            data.pessoa.contacts,
            data.pessoa.emails
          ])
          this.vistoriador = data
          this.$nextTick(() => {
            this.loading = false
          })
        })
        .catch((error) => {
          this.$uloc.dialog({
            title: 'Falha ao carregar!',
            color: 'negative',
            message: `Não conseguimos carregar o arremtante, entre em contato com o administrador.`
          })
          this.loading = false
          this.errors = error.data
          console.error(error, error.data)
        })
    },
    changeTab ({tab}) {
      this.tabActive = tab
    },
    save () {
      console.log('Save')
      this.loading = true
      let data = JSON.parse(JSON.stringify(this.vistoriador))

      // Ajusta dados
      let phones = []
      this.vistoriador.pessoa.phoneNumbers.forEach((phone, index) => {
        if (phone.phoneNumber && String(phone.phoneNumber).length > 4) {
          phones.push(phone)
        }
      })
      data.pessoa.phoneNumbers = phones

      let contacts = []
      this.vistoriador.pessoa.contacts.forEach((contact, index) => {
        if (contact.value && String(contact.value).length > 2) {
          contacts.push(contacts)
        }
      })
      data.pessoa.contacts = contacts

      data.pessoa.addresses.map((address) => {
        if (address.cityId && address.cityId.id) {
          address.cityId = address.cityId.id
        }
        if (address.city && address.city.label) {
          address.city = address.city.label
        }
      })

      data.pessoa.documents && data.pessoa.documents.map((document) => {
        if (document.type && document.type.id) {
          document.type = document.type.id
        }
      })

      if (data.pessoa.type === TIPO_PESSOA_JURIDICA) {
        // data.pessoa.documents = null
      }

      this.status = 'Processando dados do vistoriador'
      let method = data.id ? updateVistoriador(data.id, data) : newVistoriador(data)
      method
        .then(({data}) => {
          this.vistoriador.id = data.id
          const cbSuccess = () => {
            this.success = true
            this.$nextTick(() => {
              // this.$refs.timer.start()
              window.setTimeout(() => {
                this.loading = false
                this.success = false
                this.load()
              }, 3000)
              this.$uloc.window.emit(this.$root.wid, 'update', this.props)
            })
          }
          // Processa foto
          if (this.deleteFoto) {
            console.log('Deleta Foto')
          } else if (this.$fotoVistoriador) {
            this.status = 'Analisando e processando foto do vistoriador'
            updateFotoVistoriador(this.vistoriador.id, {data: this.$fotoVistoriador, filename: 'foto'})
              .then(({data}) => {
                cbSuccess()
                delete this.$fotoVistoriador
              })
              .catch((error) => {
                alert('Impossível enviar a foto do vistoriador, mas os demais dados foram processados com sucesso!')
                console.error(error)
                cbSuccess()
              })
          } else {
            cbSuccess()
          }

        })
        .catch(error => {
          console.log(error)
          this.success = false
          this.errorMessage = error.data.message
          // this.errors = this.systemError(error)
          this.$nextTick(() => {
            this.loading = false
            this.alertApiError(error)
            // this.error = true
          })
        })
    },
    deleteFotoEvent () {
      this.deleteFoto = true
    },
    updateFoto (file) {
      this.$fotoVistoriador = file
    }
  },
  components: {
    Endereco,
    Contato,
    Foto,
    Log,
    Acesso,
    ApelidoInput,
    TipoVistoriadorSelect,
    ECol,
    ERow,
    PatioInput,
    StatusSelect,
    DefaultAsyncSelect,
    WindowSuccess,
    TimerCloseWindow,
    WindowLoading,
    WindowFooter,
    WindowBody,
    WindowContent,
    ErpTabs,
    ErpTabItem,
    ErpSField,
    ErpInput,
    ErpLabel,
    ErpSelect,
    DatetimeInput,
    ErpCheckbox
  }
}
</script>
